.bg-white {
  transition: 0.5;
}

.border-custom {
  border-color: #141414 !important;
}

.w-carousel {
  width: 86%;
}

/* DARK MODE ON */
.true {
  background-color: #141414 !important;
  color: #f3f4f6 !important;
  transition: 1s;
}

.true .border-mode {
  stroke-width: 3;
  stroke: #ea580c;
}

.true .bg-white {
  background-color: #181818 !important;
}

.true .logo-rotate {
  transform: rotate(-180deg);
  transition: 1s;
}

.true .bg-blue-600 {
  background-color: #ea580c !important;
}

.true .text-gray-600 {
  color: #c1c6cf !important;
}

.true .text-gray-900 {
  color: #f3f4f6 !important;
}

.true .bg-gray-500 {
  background-color: #f3f4f6 !important;
}

.true .text-gray-600 {
  color: #f3f4f6 !important;
}

.true .hover\:bg-gray-50:hover {
  color: #141414 !important;
}

.true .bg-blue-100 {
  background-color: #ffe0b2;
}

.true .text-blue-600 {
  color: #ef6c00;
}

/* LIGHT MODE ON */
.false {
  transition: 1s;
}

.false .border-mode {
  stroke-width: 3;
  stroke: rgb(30 136 229);
}

.false .logo-rotate {
  transform: rotate(0deg);
  transition: 1s;
}

/* MOBILE RESPONSIVE */
@media (max-width: 480px) {
  .mobile-mode {
    background-color: transparent;

    --tw-shadow: 0 4px 6px -1px rgba(255, 255, 255, 0.1), 0 2px 4px -2px rgba(255, 255, 255, 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #ffffff00), var(--tw-ring-shadow, 0 0 #ffffff00), var(--tw-shadow);
  }

  .scale-mobile {
    scale: 0.9;
  }

  .w-carousel {
    width: 100% !important;
  }
}

/* TABLET RESPONSIVE */
@media (max-width: 860px) {
  .mt-mobile {
    margin-top: 4rem !important;
  }

  .mobile-toggle {
    margin-top: -16.3rem !important;
    margin-left: 47% !important;
  }
}

@media (min-width: 720px) {
  .w-carousel {
    width: 86%;
  }
}
